import React from "react"
import { Redirect, Route, Switch, useLocation } from "react-router-dom"

import {
    NavigationTabBar,
    TabProps,
} from "../../components/navigation-tab-bar/NavigationTabBar.component"
import { useFeatureFlags } from "../../hooks/useFeatureFlags.hook"
import useTitle from "../../hooks/useTitle.hook"
import { LanguageKey } from "../../pre-v3/services/localization/languages/en-US.language"
import { useServiceLocalization } from "../../pre-v3/services/localization/Localization.service"
import { OrgSignOnSettings as Admin } from "../../pre-v3/views/settings/org/sign-on/OrgSignOnSettings.component"
import { doesRouteMatch, ROUTE } from "../../routes"
import { Loader } from "../../v3/components/loader/Loader.component"
import { Banner, Variant } from "../../components/banner/Banner.component"
import { ApiKeys } from "../api-keys/ApiKeys"
import styles from "./IdentityAndAccess.module.scss"
import { EndUser } from "./identity-access/enduser/EndUser"
import { Device } from "./identity-access/device/Device"

export function IdentityAndAccess(): JSX.Element {
    useTitle([identityAndAccessKey, "settings", "adminConsole"])

    const localization = useServiceLocalization()
    const location = useLocation()

    const featureFlagsQuery = useFeatureFlags()

    if (featureFlagsQuery.status === "loading") {
        return (
            <Loader
                center
                medium
                title={localization.getString(
                    "loadingSomething",
                    localization.getString(identityAndAccessKey)
                )}
            />
        )
    }

    if (featureFlagsQuery.status === "error") {
        return <Banner label={String(featureFlagsQuery.error)} variant={Variant.ERROR} />
    }

    const getTabProps = (route: ThirdLevelRoute): TabProps => ({
        key: route,
        label: localization.getString(thirdLevelLabels[route]),
        to: route,
        active: doesRouteMatch<ThirdLevelRoute>(route, location),
    })

    const tabs = featureFlagsQuery.data.adminConsole.canAccessAdminSignOn
        ? thirdLevelRoutes
        : thirdLevelRoutes.filter((route) => route !== ROUTE.ADMIN)

    return (
        <div className={styles.container}>
            <NavigationTabBar tabs={tabs} getTabProps={getTabProps} />
            <Switch>
                {featureFlagsQuery.data.adminConsole.canAccessAdminSignOn && (
                    <Route path={ROUTE.ADMIN}>
                        <Admin
                            canConfigureAdminSignOnSettings={
                                featureFlagsQuery.data.adminConsole.canConfigureAdminSignOnSettings
                            }
                        />
                    </Route>
                )}
                <Route path={ROUTE.END_USER}>
                    <EndUser
                        canConfigureEndUserSettings={
                            featureFlagsQuery.data.adminConsole.canConfigureEndUserSettings
                        }
                        canAccessScimSetting={
                            featureFlagsQuery.data.adminConsole.canAccessScimSetting
                        }
                    />
                </Route>
                <Route path={ROUTE.DEVICE}>
                    <Device
                        canConfigureEndUserSettings={
                            featureFlagsQuery.data.adminConsole.canConfigureEndUserSettings
                        }
                    />
                </Route>
                <Route path={ROUTE.API_KEYS} component={ApiKeys} />
                <Redirect
                    to={
                        featureFlagsQuery.data.adminConsole.canAccessAdminSignOn
                            ? ROUTE.ADMIN
                            : ROUTE.END_USER
                    }
                />
            </Switch>
        </div>
    )
}

const identityAndAccessKey: LanguageKey = "identityAndAccess"

const thirdLevelRoutes = [
    ROUTE.ADMIN,
    ROUTE.END_USER,
    ROUTE.DEVICE,
    ROUTE.API_KEYS,
] satisfies ROUTE[]

type ThirdLevelRoute = (typeof thirdLevelRoutes)[number]

const thirdLevelLabels: Record<ThirdLevelRoute, LanguageKey> = {
    [ROUTE.ADMIN]: "admin",
    [ROUTE.END_USER]: "endUser",
    [ROUTE.DEVICE]: "device",
    [ROUTE.API_KEYS]: "apiKeys",
}
